import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const PropertyManagers = () => {
    return (
        <Layout>
            <Seo
                title="Property Managers - BIKESAFE"
                description="Space, value and security are important issues for property managers. Bike Dock Solutions provides bicycle storage systems that meet the needs of property managers for all types of installations. Whether you want to dedicate an entire room to bike parking or just one space, we have the solution."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Property Managers</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                        Space, value and security are important issues for property managers. Bike Dock Solutions provides bicycle storage systems that meet the needs of property managers for all types of installations. Whether you want to dedicate an entire room to bike parking or just one space, we have the solution.
                        </p>
                        <p className="flow-text">
                        We can help you maximize your bike storage by providing a CAD layout of your area.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/bristol-shelter.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"Thanks very much for the excellent job on the bike stands. They look great and are being used already!" 
                                <strong> John Hilton, SMBC Group (Property Managers), London</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PropertyManagers;